import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

 import BRAND_LOGO from '../../images/AOL_Gif.gif';
 import '../../../src/styles/home.css';
 import Footer from '../Footer';
 import SideBar from '../SideBar';

 import Notif from '../notif/Notif';


const Home = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const user = window.localStorage.getItem('email');

    const servers = useSelector(state => state.servers);

    const isSmallScreen = window.innerWidth < 600;

    useEffect(() => {
        if (servers.length) {
          setLoading(false);
        }
      }, [servers, loading]);

    return (
        <>
        {!isSmallScreen &&  <div className='sidebar-container'>
            <SideBar />
        </div>}

        {/* <Notif /> */}

        <div className='home-container'>
            <div className='logo-container'>
                <img src={BRAND_LOGO} alt='SAMURAI' className='home-image'></img>
            </div>
            <div className='home-btn'>
                {user ? 
                (                    
                  <button className='home-btn-style'  onClick={() => navigate("/health")}><span style={{ fontSize: '1.1rem' }}>Go to the health dashboard</span></button>
                ) :
                <button  className='home-btn-style' onClick={() => navigate("/auth/signin")}><span style={{ fontSize: '100%' }}>Please Login to continue</span></button>
                }
               
            </div> 
         
       </div>
       
        <div className='home-footer-container'>
          <Footer /> 
        </div>
        
        </>
  );
};

export default Home;
