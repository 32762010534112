import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { BeatLoader } from 'react-spinners';
import QRCode from 'qrcode.react';

// error notification box
import { useToasts } from 'react-toast-notifications';

// set up amplify config
import { Amplify, Auth } from 'aws-amplify';  
import awsconfig from '../../aws-exports';

import { useNavigate } from 'react-router-dom';

import '../../../src/styles/login.css';

import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import { isUserRegistered } from '../../redux/actions/checkUserRegister';
import SideBar from '../SideBar';
import Footer from '../Footer';


Amplify.configure(awsconfig);

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [userData, setUserData] = useState({});
  const [showSignup, setShowSignup] = useState(false);
  const [showResetPass, setShowResetPass] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isMFA, setIsMFA] = useState(false);
  const [MFAconfirm, setMFAconfirm] = useState(false);
  const [loadingVerificationCode, setLoadingVerificationcode] = useState(false);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
 
  const [isTOTPsetUpRequired, setIsTOTPsetUpRequired] = useState(false);
  const [TOTPsetupCode, setTOTPsetupCode] = useState('');
 
  const checkUser = useSelector((state => state.checkUser));

  const { addToast } = useToasts()


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
      setPassword(e.target.value);
  }
 
  // Sign up modal
  const handleCloseSignup = () => {
    setShowSignup(false);    
  } 

  const handleShowSignup = () => {   
    setShowSignup(true);    
  }

 // Forgote Password modal
  const handleCloseResetPass = () => {
    setShowResetPass(false);    
  } 

  const handleShowResetPass = () => {   
    setShowResetPass(true);    
  }

  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch_user_check = window.localStorage.getItem('fetch_user_check');   
    
    if (submitted && MFAconfirm && fetch_user_check === 'true') {
       // check if the email registered to the user database   
    if (checkUser.response === 'true') {
      localStorage.setItem('email', email);
      
      if (localStorage.getItem('nav') === 'report') {
        navigate("/report");
      }
      else
        navigate("/health");
    }
    else {
      let errorMsg = "You can not login now because this email is not yet registered with the Health Monitoring services. Please contact Art of Logic support (support@artoflogictech.com) to register your email.";
      //alert("You can not login now because this email is not yet registered with the Health Monitoring services. Please contact Art of Logic support (support@artoflogictech.com) to register your email.");
      addToast(errorMsg, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 9000

      });
      handleCancel();
    } 
    }
    
  }, [navigate, checkUser, MFAconfirm, submitted]);


  const confirmHandleLogin = async() => {
    try {
        await Auth.confirmSignIn(userData, code, 'SOFTWARE_TOKEN_MFA');
        setLoadingSignIn(true);
        setMFAconfirm(true);
        dispatch(isUserRegistered(email));
    } catch (error) {
      setLoadingSignIn(false);
        if (error.message === 'Invalid session for the user, session is expired.') {
          let errorMsg = 'session is expired, please login again.';
          addToast(errorMsg, {
            appearance: 'error',
            autoDismiss: true,
          });
          
          setIsMFA(false);
        }
        else {
          addToast(error.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          
        }       
    }
  }

  const handleCancel = () => {
    setIsMFA(false);
    setEmail('');
    setPassword('');
    setLoadingVerificationcode(false);
    setLoadingSignIn(false);
    localStorage.clear();
    navigate('/');
  }

  const isSmallScreen = window.innerWidth < 600;

  const verifyTOTP = async () => {
    try {
      await Auth.signIn(email, password);
      setLoadingSignIn(true);
      const result = await Auth.verifyTotpToken(
        userData,
        code
      );

     
      // set TOTP as the preferred MFA method.
      await Auth.setPreferredMFA(userData, 'TOTP');
      setLoadingSignIn(false);
      setMFAconfirm(true);
      dispatch(isUserRegistered(email));

    } catch (error) {  
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        
    }
  }

  const handleTOTPSetup = async (user) => {       
      try {
      const secretCode = await Auth.setupTOTP(user);
        setTOTPsetupCode(secretCode);
        setIsTOTPsetUpRequired(false);
      } 
      catch (error) {
      console.error('Error setting up TOTP:', error);
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleSignin = async () => {
    setSubmitted(true);
    try{
      setLoadingVerificationcode(true);
      const user = await Auth.signIn(email, password);
      setLoadingVerificationcode(false);
      setUserData(user);

      // check if first time TOTP setup required
      if (user.challengeName === "MFA_SETUP") {
        setIsTOTPsetUpRequired(true);
        await handleTOTPSetup(user); // set up TOTP (Authenticator app MFA)
      }

      // Subsequent sign in
      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        setIsMFA(true);
      }
    } catch (error) {
        console.log(error.message)
      setLoadingVerificationcode(false);
      if (error.message === 'User is not confirmed.') {
        let errorMsg = 'You can not login because user verification is pending. Please contact Art of Logic support (support@artoflogictech.com) to confirm verification.';
        addToast(errorMsg, {
          appearance: 'error',
          autoDismiss: true,
        });       
      }
      else if (error.message === 'User does not exist.') {
        let errorMsg = `${email} does not exist. Please Sign up to create a user.`;
        addToast(errorMsg, {
          appearance: 'error',
          autoDismiss: true,
        });
       
      }
      else if (error.message === 'Custom auth lambda trigger is not configured for the user pool.') {
        let errorMsg = "Password can't be blank";
        addToast(errorMsg, {
          appearance: 'error',
          autoDismiss: true,
        });      
      }
      else if (error.message === 'Invalid session for the user, session can only be used once.') {
      
        setIsMFA(false);
      }
      else {
        let errorMsg = error.message;
        addToast(errorMsg, {
          appearance: 'error',
          autoDismiss: true,
        });      
      }
    }
  }  

  return (
   <>
    {!isSmallScreen &&
    <div className='login-sidebar-container'>
      <SideBar />
    </div>}
    <div id='login-container'>
       <Card  id='input-card'>
        <Card.Body>
        <Card.Title style={{ textAlign: 'center'}}><span className='card-title'>Get started!</span></Card.Title>        
        <input size="80%" className='input-box'
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={handleEmailChange}
        />
       
        <input className='input-box'
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={handlePasswordChange}
        />

        <button className='signin-btn' variant='secondary' size={ isSmallScreen ? 'sm' : 'md' } onClick={handleSignin}>Sign in</button>

        {isTOTPsetUpRequired &&
          <button onClick={handleTOTPSetup}>Set up TOTP</button>
        }

        {!isTOTPsetUpRequired && TOTPsetupCode &&
          <div id='qrcode-text'>          
            <span style={{ opacity: '0.95' }}>Scan the QR code with your authenticator app or enter the secret code manually.</span>
            <div className='qrcode-text-code-div'><span style={{ color: 'white' }}>Secret Code:</span> <span id='qrcode-text-code'>{TOTPsetupCode}</span></div>
            <QRCode value={`otpauth://totp/ArtofLogic:${email}?secret=${TOTPsetupCode}&issuer=AWSCognito`} />
            <br></br>
            <input
              type="text"
              id='input-box-mfa'
              placeholder="Enter authenticator code "
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button className='signin-submit-code-btn' variant='secondary' onClick={verifyTOTP}>Submit code</button>
          </div>
        }
       
        { loadingVerificationCode ?
          <div style={{ marginTop: '0.0em', textAlign: 'center' }}>
            <BeatLoader size={5} color="#36d7b7"/>
          </div> :
          <div style={{ marginTop: '0.0em', height: '1.5em' }}>
          
        </div> 
        }       

        {isMFA && (
        <div>
          <div>
          <div id='signin-mfa-text'>              
              Please enter the code from your registered authenticator app. Once the code is entered, click on Submit Code button.
            </div>
            <input  id='input-box-mfa'          
              type="text"
              placeholder="Enter authenticator code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />           
            <button className='signin-submit-code-btn' disabled={loadingSignIn} onClick={confirmHandleLogin}>Submit code</button>            

            { loadingSignIn ?
           <div style={{ marginTop: '0.0em', textAlign: 'center' }}>
            <BeatLoader size={5} color="#36d7b7"/>
            </div> :
            <div style={{ marginTop: '0.0em', height: '1.5em' }}>
         
            </div> 
            }

          </div>
        </div>
      )}
      
        <div id='bottom-text'>
          <span style={{ color: 'white' }}>Dont't have an account ?</span>

          <button className='signup-btn' onClick={() => handleShowSignup()}>
            Sign up
          </button>
          <br></br>
        
          <Button variant='text' id='bottom-btn' style={{ color: 'orange', padding: '0' }} onClick={() => handleShowResetPass()}>
            Forgot password ?
          </Button>
        
        </div>
        <div id='bottom-text' style={{ color: 'lightgray', fontSize: '0.74rem' }}>*If you lost your authenticator device, please contact Art of Logic.</div>
        
        </Card.Body>
          <button className='signin-cancel-btn' onClick={() => handleCancel()}>
            Cancel
          </button>
    </Card>    

    {/* modal to view Signup modal*/}

    <Modal centered show={showSignup} onHide={handleCloseSignup} >
        <Modal.Header className='modal-color-header'>
          <div className='signup-modal-header-container'>
            <Modal.Title style={{ fontSize: '1.1rem', fontWeight: '400' }}>Create a new account</Modal.Title>
            <button className="custom-close" onClick={handleCloseSignup}>X</button>
          </div>
        </Modal.Header>
        <Modal.Body className='modal-color'>
          <SignUp  onClose={handleCloseSignup} />
        </Modal.Body>
      </Modal>

      
    {/* modal to view Forgot Password modal*/}

    <Modal centered show={showResetPass} onHide={handleCloseResetPass} >
        <Modal.Header  className='modal-color-header'>
        <div className='signup-modal-header-container'>
          <Modal.Title style={{ fontSize: '1em' }}>Reset your password</Modal.Title>
          <button className="custom-close" onClick={handleCloseResetPass}>X</button>
        </div>
        </Modal.Header>
        <Modal.Body className='modal-color'>
          <ForgotPassword  username={email} onClose={handleCloseResetPass}  />
        </Modal.Body>
      </Modal>   

    </div>
    <div className='signin-footer-container' style={{ marginTop: '10em', padding: '1em' }}>
      <Footer />
    </div>
    </>
    
  );
};

 

export default LoginPage;
