import React, {useState} from 'react';
import '../../styles/notif.css';
import Modal from 'react-bootstrap/Modal';


function Notif () {
    const [showBanner, setShowBanner] = useState(true);
    const [showInstruction, setShowInstruction] = useState(false);

    // const bannerDelayTime = 60000; // 1 minute = 60000 ms

    const handleCloseBanner = () => {
        setShowBanner(false);
        localStorage.setItem('bannerClosedTime', Date.now());
    }

    const handleShowInstruction = () => {
        setShowInstruction(true);
    }

    const handleHideInstruction = () => {
        setShowInstruction(false);
    }

    const handleHideInstructionAndBanner = () => {
        setShowInstruction(false);
        setShowBanner(false);
    }

    return (
        <>
            {showBanner&&
                <div className="banner">
                <span className="banner-closebtn" onClick={handleCloseBanner}>&times;</span>
                <p>New feature released! Check out our instruction video to learn how it works. 
                    Click <a className="banner-clickbtn" onClick={handleShowInstruction}>here</a> to watch. </p>
                <button>Acknowledged it!</button>
            </div>}

            <Modal centered style={{ height: '50em'}} show={showInstruction} onHide={handleHideInstruction}>
                <Modal.Header  style={{background: '#071d2c', padding: 0 }} className="modal-header">
                    <Modal.Title style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fafafa', fontSize: '0.8em', padding: '0 0.5em' }}>
                            <div>Instruction video</div>
                            <button title="close" className='modal-close' onClick={handleHideInstructionAndBanner}>
                                <span>X</span>
                            </button>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{background: '#071d2c', color: '#ccc' }}>
                    <iframe style={{width:'450px', height:'400px'}} src="https://www.youtube.com/embed/tgbNymZ7vqY"> </iframe>
                    <button>Got it! Show me the feature</button>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default Notif;