import React, { useEffect, useState, useCallback } from 'react';
import { Amplify } from 'aws-amplify';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import "../../src/styles/header.css";

import BRAND_LOGO from '../images/home-logo.png';
import Mute from '../images/Mute.png';
import Muted from '../images/Muted.png';

import { optEmail } from '../redux/actions/optEmail';
import CustomTooltipHeader from './tooltips/CustomTooltipHeader';
import { fetchUser } from '../redux/actions/fetchUser';
import { businessTokenMapping } from '../redux/actions/businessTokenMappingActions';

import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

const MyNavbar = ({ isSmallScreen }) => {
    const [user, setUser] = useState(localStorage.getItem('email'));
    const [checkOptOutEmail, setCheckOptOutEmail] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const emailOptResponse = useSelector(state => state.optemail);
    const businessTokenMappingData = useSelector(state => state.businessTokenMapping);
    const fetchUserResponse = useSelector(state => state.fetchUserResponse);
    
    const userFirstLetter = user?.charAt(0)?.toUpperCase();
  
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let toolTipText = "Mute email notifications";
    if (checkOptOutEmail) {
        toolTipText = "Unmute email notifications";
    }

    let confirmMsg = "Are you sure you want to mute all email notifications?";
    if (checkOptOutEmail) {
        confirmMsg = "Are you sure you want to unmute all email notifications?";
    }

    // Logout modal
  const handleCloseLogout = () => {
    setShowLogout(false);    
  } 

  const handleShowLogout = () => {   
    setShowLogout(true);    
  }

    const logout = useCallback(() => { 
      setShowLogout(false);
      localStorage.clear();
      navigate('/');  
      setUser('');
      
    }, [navigate]);

    // dispatch email opt-out submission to the backend
    const handleOptEmail = (e) => { 
      var confirmation = window.confirm(confirmMsg);

      if (confirmation) {
        setCheckOptOutEmail(e.target.checked);

        // if (e.target.checked) {
        //   dispatch(optEmail({optEmail: 0, user: user}));
        // }
        // else {
        //   dispatch(optEmail({optEmail: 1, user: user}));
        // }

        if (emailOptResponse.msg !== 201) {
          dispatch(optEmail({optEmail: 0, user: user}));
        }
        else {
          dispatch(optEmail({optEmail: 1, user: user}));
        }
      }
    };

    // handle copy text to clipboard
    // const handleCopyText = (e, text) => {
    //   navigator.clipboard.writeText(text); // Copy text to clipboard
    //   setIsCopied(true); // Set copied state to true
    //   setTimeout(() => setIsCopied(false), 300); // Reset copied state after 1.5 seconds      
    // };

// handle copy text to clipboard
const handleCopyText = (e, text) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text)
      .then(() => {
        setIsCopied(true); // Set copied state to true
        setTimeout(() => setIsCopied(false), 300); // Reset copied state after 1.5 seconds
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  } else {
    console.warn('Clipboard API not supported');
    // Fallback method, e.g., using a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 300);
    } catch (err) {
      console.error('Fallback copy failed: ', err);
    }
    document.body.removeChild(textarea);
  }
};


    useEffect(() => {
      dispatch(optEmail({ optEmail: 2, user: user }));
    }, [user, dispatch]);

    useEffect(() => {
      if (emailOptResponse.msg === 404) {
        setCheckOptOutEmail(false);
      }
      else {
        setCheckOptOutEmail(true);
      }
    }, [emailOptResponse]);

    useEffect(() => {
      if (user)
        dispatch(businessTokenMapping({user: user}));
    }, [user, dispatch]);

  useEffect(() => {
    setUser(localStorage.getItem('email'));

  }, [navigate]);

  useEffect(() => {
    dispatch(fetchUser(user));
  }, [user, dispatch]);
  

  return (
    <div className='nav-container'>
      {isSmallScreen ? 
       <div>
          <img  src={BRAND_LOGO} alt='' className='nav-container-img'/>
       </div>
      : null
      }
      {! isSmallScreen ?
         // for large screens
        <ul className='header-ul'>
          {user ? (
            <>
              <li key="optOut" style={{ marginRight: '2%', marginTop: '1.4%' }}>
                <CustomTooltipHeader text={ toolTipText }>
                  <button type='text' onClick={handleOptEmail} className='mute-btn'>
                    <img alt='Opt-Out Email Notifications' className='mute-icon' src={ checkOptOutEmail ? Muted : Mute}></img>
                  </button>
                </CustomTooltipHeader>
              </li>
          
              <li key="avatar" style={{ marginTop: '1%', marginRight: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>                 
                  <div style={{ marginRight: '0.9rem', fontSize: '0.7rem', color: 'darkgray'}}>                  
                    <Button className='avatar' onClick={() => handleShowLogout()}>{userFirstLetter}</Button>
                  </div>
                </div>
              </li>
            </>
          ):
          <li>
            <a href='/#auth/signin' className='login-link'>Login</a>
          </li>        
          }
        </ul> 
      : 
      // for small screens
      (
          <section className="p-menu1">
            <nav  className="navigation" role="navigation">
              <input id="toggle1" type="checkbox" />
              <label className="hamburger1" for="toggle1">
                <div className="top"></div>
                <div className="meat"></div>
                <div className="bottom"></div>
              </label>
            
              <nav className="menu1">
                <a className="link1" href="/#health">Health-info</a>
                <a className="link1" href="/#report">Generate-reports</a>
                <a className="link1" href='/#lifecycle'>Asset-lifecycle</a>

                { (fetchUserResponse?.role === 'L1' || fetchUserResponse?.role === 'L2') &&
                    <a className="link1" href="/#admin">Access-control</a>
                }
                 { (fetchUserResponse?.role === 'L1')  &&
                    <a className="link1" href="/#super-admin">Admin</a>                  
                }
                
                  {user ? (           
                    <Button
                      className="link1"
                      style={{ borderRadius: 0, margin: '0' }}
                      type='text'
                      variant='danger'
                      onClick={logout}
                      >
                      Logout
                  </Button>
                  ):                 
                  <a className="link1" href='/#auth/signin' >Login</a>                     
                  }
              </nav>
            </nav>
          </section>
        )
      }

       {/* modal to view Logout modal*/}

      <Modal size='sm' style={{ marginTop: '3%', marginLeft: '38%' }} show={showLogout} onHide={handleCloseLogout}>
          <Modal.Header  className='modal-color-header'>           
            <Modal.Title className='header-modal-title-container' style={{ width: '100%' }}>
              <div>{user}</div>
              <button className="custom-close" onClick={handleCloseLogout}>X</button>
            </Modal.Title>
            
          </Modal.Header>
          <Modal.Body className='modal-color'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div className='avatar-modal'>{userFirstLetter}</div> 
            </div>
            
            <div className='business-token-table-container'>              
              <table style={{ width: '100%', textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th className='business-token-table-header'>
                      Business
                    </th>
                    <th className='business-token-table-header'>
                    Token                   
                    </th>
                  </tr>
                </thead>
                <tbody>
                {
                  businessTokenMappingData.map(data => (
                    data &&
                    <tr key={data.business}>
                      <td className='business-token-table-column'>
                        {data?.business}
                      </td>
                      <td className='business-token-table-column'>
                        <button
                          className='business-token-table-btn-copy'
                          title="Copy this token"
                          onClick={(e) => handleCopyText(e, data.token)}
                          >
                          <FontAwesomeIcon icon={faCopy} color={isCopied ? 'black' : '#E8E8E8'} />
                        </button>
                        <span>{data.token}</span>                          
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>

              <div style={{ display: 'flex', justifyContent: 'center'}}>
                <button
                  className='signout-btn'            
                  variant='text'
                  onClick={logout} >
                    <span>
                      <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
                    </span>
                    {' '}
                    <span className='signout-btn-text'>Sign out</span>
                </button>
              </div>            
          </Modal.Body>
        </Modal>
    </div>
    
        
  );
};

export default MyNavbar;
